import { Icon } from "components/Icon"
import { t } from "locales"
import {
    Dispatch,
    ReactNode,
    SetStateAction,
    createContext,
    useContext,
    useState
} from "react"
import "swiper/css"
import "swiper/css/keyboard"
import "swiper/css/navigation"
import "swiper/css/pagination"
import { A11y, Keyboard, Navigation, Pagination } from "swiper/modules"
import { Swiper, SwiperSlide } from "swiper/react"

import Box from "@mui/material/Box"
import IconButton from "@mui/material/IconButton"
import Modal from "@mui/material/Modal"
import { styled } from "@mui/material/styles"

import { IGalleryImage } from "data/contentData/api/content"

import { Image } from "./Image"

function Gallery({
    gallery,
    current
}: {
    gallery: IGalleryImage[]
    current: number | undefined
}) {
    const { setCurrent } = useGallery()
    const closeGallery = () => setCurrent(undefined)

    return (
        <Modal
            open={true}
            onClose={closeGallery}
            sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center"
            }}>
            <StyledBox>
                <StyledSwiper
                    modules={[Navigation, Pagination, A11y, Keyboard]}
                    spaceBetween={50}
                    effect="fade"
                    slidesPerView={1}
                    navigation
                    initialSlide={current}
                    pagination={{
                        clickable: true,
                        dynamicBullets: true,
                        dynamicMainBullets: 10
                    }}
                    scrollbar={{ draggable: true }}
                    a11y={{
                        prevSlideMessage: t["back"],
                        nextSlideMessage: t["next"]
                    }}
                    keyboard={{
                        enabled: true,
                        onlyInViewport: false
                    }}>
                    {gallery.map(image => (
                        <StyledSwiperSlide key={image.index}>
                            <IconButton
                                sx={{ float: "right" }}
                                onClick={closeGallery}>
                                <Icon name="close" />
                            </IconButton>
                            <StyledImage
                                nativeImg
                                alt={image.alt || image.title || ""}
                                src={image.src}
                            />
                            <StyledParagraph>{image.caption}</StyledParagraph>
                        </StyledSwiperSlide>
                    ))}
                </StyledSwiper>
            </StyledBox>
        </Modal>
    )
}

const StyledParagraph = styled("p")(({ theme }) => ({
    textAlign: "center",
    margin: theme.spacing(1, 0, 4, 0)
}))

const StyledSwiper = styled(Swiper)(() => ({
    width: "100%",
    height: "50%"
}))

const StyledSwiperSlide = styled(SwiperSlide)(() => ({
    backgroundPosition: "center",
    backgroundSize: "cover"
}))

const StyledImage = styled(Image)(() => ({
    display: "block",
    objectFit: "contain",
    width: "100%",
    maxHeight: "50vh"
}))

const StyledBox = styled(Box)(({ theme }) => ({
    backgroundColor: theme.palette.common.white,
    padding: theme.spacing(2),
    width: "80vw"
}))

const GalleryContext = createContext<{
    setCurrent: Dispatch<SetStateAction<number | undefined>>
}>({
    setCurrent: () => void 0
})

interface GalleryProviderProps {
    gallery: IGalleryImage[]
    children: ReactNode
}

export function GalleryProvider({ children, gallery }: GalleryProviderProps) {
    const [current, setCurrent] = useState<number | undefined>()
    const _gallery = gallery?.map((image, index) => ({
        ...image,
        index
    }))
    const showGallery = current !== undefined && _gallery?.length

    return (
        <GalleryContext.Provider value={{ setCurrent }}>
            {children}
            {showGallery && (
                <Gallery
                    gallery={_gallery}
                    current={current}
                />
            )}
        </GalleryContext.Provider>
    )
}

export function useGallery() {
    return useContext(GalleryContext)
}
