import { Icon } from "components/Icon"
import { SuggestionOrTermOption } from "components/Layout/Header/SearchField"
import { useCombobox } from "downshift"
import { t } from "locales"
import React, { useCallback, useEffect, useRef, useState } from "react"

import Box from "@mui/material/Box"
import IconButton from "@mui/material/IconButton"
import InputBase from "@mui/material/InputBase"
import List from "@mui/material/List"
import ListItem from "@mui/material/ListItem"
import ListItemButton from "@mui/material/ListItemButton"
import ListItemText from "@mui/material/ListItemText"
import ListSubheader from "@mui/material/ListSubheader"
import Skeleton from "@mui/material/Skeleton"
import Typography from "@mui/material/Typography"
import { grey } from "@mui/material/colors"
import { styled, useTheme } from "@mui/material/styles"
import useMediaQuery from "@mui/material/useMediaQuery"

export interface ISearchProps {
    suggestions: SuggestionOrTermOption[]
    onChange: (value: string) => void
    onSelect: (value: string | SuggestionOrTermOption | null) => Promise<void>
    status: "error" | "idle" | "loading" | "success" | string
    initialValue: string
}

/**
 * Handles search input and popover for suggestions.
 *
 * @param suggestions list over suggestions that will present itself below the input field
 * @param onChange when a change occurs to comboboxinput this updates textfield state
 * @param onSelect when a comboboxoption is pressed a redirect occurs
 * @param status this is the state of the query
 * @param initialValue search query used in routing
 */

export default function SearchCombobox({
    suggestions,
    onChange,
    onSelect,
    status,
    initialValue
}: ISearchProps) {
    const inputRef = useRef<HTMLInputElement>(null)
    const listRef = useRef<HTMLUListElement>(null)
    const theme = useTheme()
    const mdUp = useMediaQuery(theme.breakpoints.up("md"))

    const [items, setItems] = useState(suggestions)
    const stateReducerWithoutToggle = useCallback(
        (_state, actionAndChanges) => {
            const { type, changes } = actionAndChanges
            switch (type) {
                case useCombobox.stateChangeTypes.InputClick:
                    return { ...changes, isOpen: true }
                default:
                    return changes
            }
        },
        []
    )

    const {
        inputValue,
        isOpen,
        highlightedIndex,
        setHighlightedIndex,
        getMenuProps,
        getItemProps,
        getInputProps,
        selectItem,
        setInputValue
    } = useCombobox({
        id: "downshift-combobox",
        items,
        initialInputValue: initialValue,
        onInputValueChange(typedValue) {
            onChange(typedValue.inputValue)
        },
        onSelectedItemChange: ({ selectedItem }) => {
            onSelect(selectedItem as SuggestionOrTermOption)
        },
        onHighlightedIndexChange: ({ highlightedIndex: highlighted, type }) => {
            if (
                highlighted >= 0 &&
                items[highlighted].type &&
                items[highlighted].type === "subheader"
            ) {
                if (type === useCombobox.stateChangeTypes.InputKeyDownArrowDown)
                    setHighlightedIndex(highlighted + 1)
                if (type === useCombobox.stateChangeTypes.InputKeyDownArrowUp)
                    setHighlightedIndex(highlighted - 1)
            }
            if (highlighted === 1 && listRef.current) {
                listRef.current.scrollTo(0, 0)
            }
        },
        itemToString(item) {
            return item ? item.name : ""
        },
        onStateChange(changes) {
            if (
                changes.type ===
                    useCombobox.stateChangeTypes.InputKeyDownEnter &&
                highlightedIndex == -1
            ) {
                onSelect(inputValue)
            }
            if (
                changes.type === useCombobox.stateChangeTypes.InputClick &&
                inputValue.length > 0
            ) {
                onChange(inputValue)
            }
        },
        stateReducer: stateReducerWithoutToggle
    })

    useEffect(() => {
        if (mdUp && inputRef.current) {
            inputRef.current.focus()
        }
    }, [inputRef, mdUp])

    useEffect(() => {
        if (initialValue) {
            setInputValue(initialValue)
        }
    }, [initialValue, setInputValue])

    useEffect(() => {
        if (suggestions.length > 0) {
            setItems(suggestions)
        }
    }, [setItems, suggestions])

    const handleClose = () => {
        selectItem(null)
    }

    const isListVisible =
        isOpen && (inputValue.trim().length > 2 || items.length > 0)

    let ListComponent

    switch (status) {
        case "loading":
            ListComponent = [
                <StyledListSubheader
                    key={"searchingSubheader"}
                    disableSticky>
                    {t["search"]["searching"]}
                </StyledListSubheader>,
                ...Array(5)
                    .fill("")
                    .map((_, index) => {
                        const id = "loading-" + index
                        return (
                            <StyledListItem key={"list-item-" + id}>
                                <Skeleton
                                    key={"skeleton-" + id}
                                    width={"100%"}
                                    height={"100%"}
                                />
                            </StyledListItem>
                        )
                    })
            ]
            break
        case "idle":
        case "success":
            if (suggestions.length > 1) {
                ListComponent = items.map((item, index) => {
                    if (item.type === "subheader") {
                        return (
                            <StyledListSubheader
                                disableSticky
                                key={item.id}>
                                {item.name}
                            </StyledListSubheader>
                        )
                    }

                    const topic = (item as SuggestionOrTermOption).topic
                        ? ` (${(item as SuggestionOrTermOption).topic})`
                        : ""

                    return (
                        <StyledListItemButton
                            highlighted={(
                                index === highlightedIndex
                            ).toString()}
                            {...getItemProps({
                                item,
                                index,
                                key: item.id
                            })}>
                            <ListItemText
                                key={"ItemText" + item.id}
                                primary={
                                    <StyledListItemTypography
                                        key={"ItemTypography" + item.id}>
                                        {inputValue
                                            ? boldInputValue(
                                                  `${item.name}${topic}`,
                                                  inputValue,
                                                  item.id
                                              )
                                            : `${item.name}${topic}`}
                                    </StyledListItemTypography>
                                }
                            />
                        </StyledListItemButton>
                    )
                })
                break
            }
        default:
            ListComponent = (
                <StyledListSubheader>
                    {t["search"]["no-hits"]}
                </StyledListSubheader>
            )
    }

    return (
        <>
            <StyledInputBox>
                <StyledInputBase
                    placeholder={
                        mdUp
                            ? t["search"]["search-placeholder"]
                            : t["search"]["search"]
                    }
                    {...getInputProps({
                        inputRef,
                        id: "downshift-0-input"
                    })}
                />
                {inputValue && (
                    <IconButton
                        size="small"
                        sx={{ p: 1 }}
                        onClick={handleClose}>
                        <Icon
                            name="close"
                            color="disabled"
                        />
                    </IconButton>
                )}
            </StyledInputBox>

            <StyledListBox islistvisible={isListVisible.toString()}>
                <StyledList
                    {...getMenuProps({ id: "downshift-0-menu", ref: listRef })}>
                    {ListComponent}
                </StyledList>
                {mdUp && (
                    <StyledPopoverFooter>
                        <StyledDirectionsTypography>
                            {`${t["search"]["arrow-directions"]["press"]}`}
                            <b>{`${t["search"]["arrow-directions"]["arrow-up"]}`}</b>
                            {`${t["search"]["arrow-directions"]["and"]}`}
                            <b>{`${t["search"]["arrow-directions"]["down"]}`}</b>
                            {`${t["search"]["arrow-directions"]["to-navigate"]}`}
                        </StyledDirectionsTypography>
                    </StyledPopoverFooter>
                )}
            </StyledListBox>
        </>
    )
}

/**
 * This function makes parts of the search results that are the same as text in inputfield bold
 * @param name - text of search result
 * @param inputValue - text in input field
 * @param id - an id used for key
 * @returns an array of texts and <strong> elements with text
 */

function boldInputValue(
    name: string,
    inputValue: string,
    id: string | null | undefined
) {
    const regExp = new RegExp(`(${inputValue})`, "i")
    const fragments = name.split(regExp).map((fragmentValue, index) => {
        return {
            fragment: fragmentValue,
            key: `strong-${index}-${id}`,
            isBold: fragmentValue.toLowerCase() === inputValue.toLowerCase()
        }
    })

    return fragments.map(({ fragment, key, isBold }) => {
        return isBold ? <strong key={key}>{fragment}</strong> : fragment
    })
}

const StyledInputBase = styled(InputBase)(({ theme }) => ({
    width: "100%",
    paddingLeft: theme.spacing(2),
    marginTop: theme.spacing(0.5),
    lineHeight: theme.spacing(3)
}))

const StyledInputBox = styled(Box)(({}) => ({
    display: "flex",
    flexDirection: "row",
    alignItems: "center"
}))

const StyledDirectionsTypography = styled(Typography)(({ theme }) => ({
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
    padding: theme.spacing(0, 1),
    borderRadius: theme.spacing(1)
}))

const StyledList = styled(List)(({ theme }) => ({
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    paddingBottom: theme.spacing(0.5),
    maxHeight: theme.spacing(58),
    overflowY: "scroll",
    "&::-webkit-scrollbar": {
        width: "4px"
    },
    "&::-webkit-scrollbar-track": {
        backgroundColor: theme.palette.grey[500]
    },
    "&::-webkit-scrollbar-thumb": {
        backgroundColor: grey[500]
    }
}))

const StyledListBox = styled(Box)<{ islistvisible: string }>(
    ({ theme, islistvisible }) => ({
        visibility: islistvisible === "true" ? "visible" : "hidden", //must be rendered using hiden otherwise lots of errors from list
        marginTop: theme.spacing(1),
        borderRadius: theme.spacing(1),
        overflow: "hidden",
        backgroundColor: theme.palette.common.white,
        position: "absolute",
        width: "100%",
        boxShadow: `${theme.spacing(0, 0.25, 0.75, 0)} ${
            theme.palette.common.black
        }`
    })
)

const StyledListItem = styled(ListItem)(({ theme }) => ({
    margin: 0,
    padding: 0,
    width: "100%",
    height: theme.spacing(6)
}))

const StyledListItemButton = styled(ListItemButton)<{ highlighted: string }>(
    ({ theme, highlighted }) => ({
        margin: theme.spacing(0),
        padding: theme.spacing(1),
        borderRadius: theme.spacing(0.5),
        backgroundColor: highlighted === "true" ? theme.palette.grey[100] : "",
        outline:
            highlighted === "true" ? `1px solid ${theme.palette.grey[300]}` : ""
    })
)

const StyledListItemTypography = styled(Typography)(({ theme }) => ({
    fontFamily: theme.typography.body2.fontFamily,
    color: theme.palette.primary.main
}))

const StyledListSubheader = styled(ListSubheader)(({ theme }) => ({
    fontFamily: theme.typography.subtitle1.fontFamily,
    padding: 0,
    color: theme.palette.quaternary.main,
    margin: theme.spacing(1.5, 0, 0, 1),
    paddingBottom: 0,
    opacity: 0.75
}))

const StyledPopoverFooter = styled("div")(({ theme }) => ({
    display: "flex",
    position: "relative",
    bottom: 0,
    justifyContent: "center",
    alignItems: "center",
    height: theme.spacing(5),
    backgroundColor: theme.palette.grey[300],
    borderRadius: theme.spacing(0, 0, 0.5, 0.5)
}))
