import getServerToken from "data/getServerToken"
import { get, post, put } from "lib/http/http"

interface IClaim {
    key: string
    value: string
}
/**
 * Creates a new claim for a user
 * @param userGuid
 * @param claim The (not previously existing) claim to create
 * @returns the created claim
 */
export async function createClaim(userGuid: string, claim: IClaim) {
    const authToken = await getServerToken()
    const url = `${process.env.NEXT_PUBLIC_CUSTOMER_MANAGER_API}/api/customer/${userGuid}/claim`
    const { data } = await post(url, JSON.stringify(claim), authToken)
    return data
}

/**
 * Update an existing claim for a user
 * @param userGuid
 * @param current The old claim
 * @param newValue the new value of the old claim
 * @returns
 */
export async function updateClaim(
    userGuid: string,
    current: IClaim,
    newValue: string
) {
    const authToken = await getServerToken()
    const url = `${process.env.NEXT_PUBLIC_CUSTOMER_MANAGER_API}/api/customer/${userGuid}/claim?claimType=${current.key}&oldValue=${current.value}&newValue=${newValue}`
    await put(url, "", authToken)
}

/**
 * Get list of values for claim type
 * @param userGuid
 * @param type Claim type ("name")
 * @returns List of { key, value }
 */
export async function getClaim(userGuid: string, type: string) {
    const authToken = await getServerToken()
    const url = `${process.env.NEXT_PUBLIC_CUSTOMER_MANAGER_API}/api/customer/${userGuid}/claim?claimType=${type}`
    const { data } = await get<{ key: string; value: string }[]>(url, authToken)
    return data
}

export interface ISubscription {
    id: number
    start: string
    stop: string | null
    product: {
        id: number
        name: string
        applicationId: number
    }
}

/**
 * Get all subscriptions for a user
 * @param userGuid
 * @returns List of subscriptions
 */
export async function getSubscriptions(userGuid: string) {
    const authToken = await getServerToken()
    const url = `${process.env.NEXT_PUBLIC_CUSTOMER_MANAGER_API}/api/customer/${userGuid}/subscription`
    const { data } = await get<ISubscription[]>(url, authToken)
    return data
}
